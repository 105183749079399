import { gql } from "@apollo/client/core";

const SELLERS_SLIDERS = gql`
  query SELLERS_SLIDERS($sort: String, $limit: Int, $start: Int, $where: JSON) {
    sellersliders(sort: $sort, limit: $limit, start: $start, where: $where) {
      id
      name
      order
      link
      platform
      logo {
        id
        url
      }
    }
  }
`;

export { SELLERS_SLIDERS };