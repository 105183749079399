import React, { Fragment } from "react";

const pointer = {
  cursor: "pointer",
};

export default function License() {
  const companyLicenses = [
    {
      name: "رخصة فال للمزادات العقارية",
      imageUrl:
        "https://storage.googleapis.com/mobasher-assets/fal_llmzadat_9485a979e2.png",
      url: "https://eservicesredp.rega.gov.sa/public/OfficesBroker/LicenseDetails/Real/08dbd934-b750-4384-843d-37ecc18e8c32",
    },
    {
      name: "رخصة فال للوساطة والتسويق للمنصات العقارية",
      imageUrl:
        "https://storage.googleapis.com/mobasher-assets/fal_llwsatt_ff8b4ef6e5.png",
      url: "https://eservicesredp.rega.gov.sa/public/OfficesBroker/LicenseDetails/Bmlp/08dbc58d-8083-47ed-8ad8-0d7d090c35ab",
    },
    {
      name: "السجل التجاري",
      imageUrl:
        "https://storage.googleapis.com/mobasher-assets/shrkt_mbashr_lltsmym_3_1_1bab4d59c8.png",
      url: "https://storage.googleapis.com/mobasher-assets/shrkt_mbashr_lltsmym_3_1_1bab4d59c8.png",
    },
  ];

  return (
    <Fragment>
      {companyLicenses.map((license, index) => (
        <div key={index} className="col-12 col-md-8">
          <div className="card mb-4">
            <a href={license && license.url} target="_blank" rel="noreferrer">
              <img
                src={license && license.imageUrl}
                className="card-img-top"
                alt={license && license.name}
                title={license && license.name}
                height="auto"
                width="auto"
              />
            </a>
            <div className="card-body">
              <h5 className="card-title text-center">
                <a
                  href={license && license.url}
                  target="_blank"
                  rel="noreferrer"
                  style={pointer}
                >
                  {license && license.name}
                </a>
              </h5>
            </div>
          </div>
        </div>
      ))}
    </Fragment>
  );
}
