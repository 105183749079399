import React from "react";
// import AboutUsCounter from "../../common/AboutUsCounter";
import Breadcrumb from "../../common/Breadcrumb";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
import ContactWrapper from "./ContactWrapper";

function Contact() {
  return (
    <>
      <Header />
      <Breadcrumb pageName="اتصل بنا" pageTitle="اتصل بنا" />
      <ContactWrapper />
      {/* <AboutUsCounter /> */}
      <Footer />
    </>
  );
}

export default Contact;
