import React from "react";

export default function Usage() {
  return (
    <>
      <h3>مقدمة</h3>
      <p>
        لغرض من اتفاقية خصوصية الاستخدام، والشروط والاحكام، والسياسات وضعت
        لحماية وحفظ حقوق كل من شركة مباشر وعملاءها الذين يستخدمون الموقع سواء
        متصفحين أو مسجلين في المنصة، وعند قبولك والموافقة على اتفاقية استخدام
        المنصة فأنت تقر على جميع الأحكام والشروط الواردة في هذه (الاتفاقية). كما
        أنك تضمن حقك القانوني للدخول في هذه الاتفاقية واستخدام الموقع وفقاً
        لجميع الشروط والأحكام الواردة فيها.
      </p>
      <p>
        تسعى شركة مباشر إلى خلق المصداقية في التعامل بين المعلنين والراغبين في
        الشراء حيث اتخذت عدت اعتبارات ومنها توفير المعلومات على موقعها بدقة
        متناهية وذلك بعدم السماح للمعلنين الا بعد التأكد من مصداقيتهم الكاملة
        وأهليتهم للبيع حسب ما تقره الهيئة العامة للعقار. إلا أنه قد ترد بعض
        الأخطاء من حين لآخر. ولا تتحمل شركة مباشر أي مسؤولية عن أي خطأ في
        المعلومات التي يتضمنها الموقع او التطبيق ، وتستقبل شركة مباشر جميع
        شكاويكم ومقترحاتكم وتعالجها بطريقة احترافية.
      </p>
      <p>
        شركة مباشر هي شركة سعودية ١٠٠ ٪ ومقرها الرئيسي مدينة الرياض. ويشار إليها
        بهذه الاتفاقية باسم (شركة مباشر) أو (نحن) أو (لنا)، وتمثل هنا الطرف
        الأول.
      </p>
      <p>
        "العميل" هو الفرد أو المؤسسة أو الشركة الذي يصل إلى الموقع ويستفيد من
        خدماته بشكل مباشر أو غير مباشر سواء كان ذلك بتسجيل أو من دون تسجيل وهو
        إما بائع أو مشتري أو مؤجِّر أو يرغب بالإيجار أو مكتب عقاري أو مسوِّق
        عقاري أو أي شخص يحمل رقم معلن من الهيئة العامة للعقار ويمثل هنا الطرف
        الثاني.
      </p>
      <p>
        تخضع بنود وشروط وأحكام هذه الاتفاقية والمنازعات القانونية للقوانين
        والتشريعات والأنظمة المعمول بها في المملكة العربية السعودية
      </p>
      <h3>شروط الاستخدام</h3>
      <div>
        <p>
          بصفتك "الطرف الثاني" في هذه الاتفاقية فإن دخولك للمنصة للاستفادة من
          خدمات الموقع يلزمك بما يلي:
        </p>

        <ol>
          {[
            "عدم الإعلان أو تحميل محتوى أو عناصر غير ملائمة للتصنيفات المتاحة في الموقع والمسموح ببيعها.",
            "عدم الاختراق أو التحايل على قوانين وسياسة وأنظمة الموقع أو أي حقوق تتعلق بطرف ثالث.",
            "عدم نسخ الإعلان من منصة مباشر وإعادة نشره في مواقع أخرى.",
            "عدم استخدام أي وسيلة غير شرعية للوصول للإعلانات أو لبيانات المستخدمين الآخرين أو انتهاك لسياسة وحقوق شركة مباشر أو الوصول لمحتوى الموقع أو تجميع وتحصيل معلومات وبيانات تخص شركة مباشر أو عملاء الموقع والاستفادة منها بأي شكل من الأشكال أو إعادة نشرها.",
            "عدم انتهاك القيود على الموقع أو التطبيق أو أي تجاوز أو تحايل لخرق التدابير الأخرى المستخدمة بهدف منع أو تقييد الوصول لهذا الموقع.",
            "عدم استخدام خدماتنا إذا كنت غير مؤهل قانونيا لإتمام هذه الاتفاقية.",
            "عدم القيام بتزوير أي ملف مرفق لمباشر.",
            "عدم القيام بنسخ حق المعلن في أي إعلان تم اضافته.",
            "عدم نقل حسابك أو نشاطك إلى مواقع أخرى بالوقت الذي هو يحمل شعار أو خدماتنا.",
            "عدم انتهاك حقوق الطبع والنشر والعلامات التجارية، وبراءات الاختراع والدعاية وقواعد البيانات أو غيرها من حقوق الملكية أو الفكرية التي تنتمي لشركة مباشر أو مرخصة لشركة مباشر.",
            "عدم انتهاك حقوق الآخرين الملكية أو الفكرية أو براءة الاختراع.",
            "عدم جمع معلومات عن مستخدمي الموقع الآخرين لأغراض تجارية أو غيرها.",
            "عدم الإقدام على أي ما من شأنه إلحاق الضرر بسمعة شركة مباشر.",
            "عدم انتحال صفة شركة مباشر أو ممثل لها أو موظف فيها أو أي صفة توحي بأنك تابع للشركة ما لم يكون لديك أذن رسمي من الشركة.",
            "عدم الحصول أو محاولة الحصول على دخول غير مصرح به للموقع أو أي حساب لعضو متواجد على الموقع، أو انتحال أي شخصية أو تحريف العضوية مع شخص آخر.",
            "تقع على مسؤوليتك الشخصية التقيد بجميع شروطنا و أحكامنا الخاصة والتي تتضمن الدفع الكامل وفي الوقت المناسب لجميع المبالغ المستحقة مع الامتثال لجميع القواعد المتعلقة حول العمولة والرسوم.",
          ].map((point) => (
            <li key={point}>{point}</li>
          ))}
        </ol>
      </div>
      <h3>شروط الإعلان في منصة مباشر للعقارات :</h3>
      <ul>
        {[
          "يجب أن يكون الإعلان لبيع أو تأجير أو تقبيل عقار فقط.",
          "يلتزم المعلن بصحة كافة المعلومات المدخلة.",
          "يجب أن يكون الإعلان كامل التفاصيل وفي القسم الصحيح.",
          "يلتزم المعلن بأن تكون الصور المضافة في الإعلان لنفس العقار المعلن عنها.",
          "يلتزم المعلن بإرفاق صور واقعية للعقار المعلن عنه.",
          "يلتزم المعلن بعدم إرفاق صور نموذج أو صور رمزية.",
          "يلتزم المعلن بمتابعة إعلانه وتحديثه بشكل مستمر.",
          "يلتزم المعلن بالرد على عملائه سواء عبر الاتصال أو الرسائل.",
          "يلتزم المعلن أن يكون سعودي الجنسية موثق عبر النفاذ الوطني.",
        ].map((point) => (
          <li key={point}>{point}</li>
        ))}
      </ul>
      <h3>سياسة الاسترجاع:</h3>
      <p>تنص شركة مباشران سياسة الاسترجاع تتضمن ما يلي:</p>
      <p>
        البيع في منصة مباشر يتم عن طريق المزادات الزمنية الالكترونية والمنقولة
        نقل مباشر والظرف المغلق وبالتالي فإن الراغب في استخدام منصة مباشر سوف
        يقر بدخوله في المزادات والمزايدة على ما يرغب من عقارا بعد دفع تأمين دخول
        المزاد، وشركة مباشر لا تضمن استرجاع التامين بعد وصول المزايد إلى أعلى
        مزايدة وترسية البيع عليه بل على العكس من ذلك سوف تحمله رسوم إعادة نشر
        المزاد مرة أخرى.
      </p>
      <h3>مسؤولية شركة مباشر</h3>
      <p>
        *تم انشاء منصة مباشر لتكون شامله لجميع أنواع العقارات في جميع انحاء
        المملكة وتقدم منصة مباشر الخدمات العقارية المتنوعة وإمكانية البحث او
        الإعلان عن العقارات ويتيح التواصل مع المعلن مباشرة وان المعلن مسؤول عن
        صحة تفاصيل الإعلان بما في ذلك الأوراق المساندة التي تم ارفاقها والالتزام
        بالقوانين واللوائح المطبقة في الدولة بما يتعلق ببيع وتسويق العقارات وان
        الأهمية أن يفهم العميل ان كامل المسؤولية تقع على عاتق المعلن مباشرة
      </p>
      <p>
        *شركة مباشر لا تقدم أي ضمانات ولا نتحمل أي مسؤولية في حالة عدم التزام
        المستخدم بسياسة استخدام الموقع ولا نتحمل المسؤولية عن أي مخاطرة أو أضرار
        أو تبعات أو خسائر تقع على البائع أو المشتري أو أي طرف آخر وعلى من لحق به
        الضرر إبلاغنا بذلك من خلال التواصل على الايميل التالي: info@mobasher.sa
        وشرح الضرر الواقع عليه وستقوم شركة مباشر باتخاذ الإجراء حسب نوع الواقعة
        دون أية مسؤولية
      </p>
      <p>
        الإجراءات اللازم اتخاذها من قبل شركة مباشر عند تعرضك لضرر ما هي : (توثيق
        وأرشفة الشكاوى والوقائع المخالفة والاحتفاظ بها لتقديمها للجهات ذات
        العلاقة في حال لزم الأمر في المبادرة لتقديمها أو تم طلبها في أي وقت).
      </p>
      <p>
        تسجيلك في منصة شركة مباشر يعني أنك تخولنا في حفظ بياناتك التي قمت
        بإدخالها بخوادم الشركة ولنا حق الاطلاع عليها ومراجعتها. كما أنك توافق
        على أحقيتنا في مراقبة جميع العمليات التي تقوم بها
      </p>
    </>
  );
}
