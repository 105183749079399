import React from "react";

export default function Privacy() {
  return (
    <>
      <p>
        نقدر مخاوفكم واهتمامكم بشأن خصوصية بياناتكم على شبكة الإنترنت. لقد تم
        إعداد هذه السياسة لمساعدتكم في تفهم طبيعة البيانات التي نقوم بتجميعها
        منكم عند زيارتكم لموقعنا على شبكة الانترنت وكيفية تعاملنا مع هذه
        البيانات الشخصية.
      </p>
      <p>
        لن يتم جمع أية معلومات خاصة بك دون سبب لذلك محدد مسبقاً. وسوف نقوم بجمع
        واستخدام المعلومات الشخصية فقط مع هدف تحقيق هذه الأسباب المحددة، أو لأية
        أغراض أخرى متوافقة، ولن يتم جمع أية معلومات أخرى دون أن نحصل على موافقة
        من الفرد أو المنظمات المعنية كما يقتضي القانون. سوف نحتفظ فقط المعلومات
        الشخصية طالما كان ذلك ضروريا لتحقيق هذه الأغراض. وسوف نقوم بجمع هذه
        المعلومات الشخصية عبر وسائل مشروعة فقط، وعند الحاجة فقط مع علم أو موافقة
        الشخص المعني.
      </p>
      <div>
        ما هي المعلومات التي نقوم بجمعها؟
        <ul>
          {[
            "معلومات شخصية تتضمن بريدك الإلكتروني واسمك ورقم هاتفك نوع الجهاز عنوان بروتوكول شبكة الإنترنت (IP).",
            "الأوراق المساندة بما فيها الصك والأوراق الثبوتية لتصنيف الاعلان",
            "معلومات الإعلانات، ومنها الموقع والصور تفاصيل الإعلان.",
            "معلومات أخرى، ومنها معلومات عن تصفحك للموقع أو التطبيق.",
            "بيانات الدفع.",
          ].map((point) => (
            <li key={point}>{point}</li>
          ))}
        </ul>
      </div>
      <div>
        بماذا سيتم استخدام هذه المعلومات؟
        <ul>
          {[
            "لمنحك تجربة خاصة وتقديم أفضل الخدمات (والعمل على تلبية احتياجاتك الفردية على أفضل وجه عبر تزويدنا بمعلوماتك الشخصية)",
            "لتطوير أداء استخدام الموقع: (نسعى دوماً لتحسين خدمات الموقع استناداً على المعلومات التي نستلمها منك)",
            "لتحسين خدمة العملاء (هذه المعلومات تساعدنا على الإجابة بشكل أفضل على طلباتك الموجهة لفريق خدمة العملاء ودعم احتياجاتهم وتحليل حركة البيانات على الموقع.",
            "المشاركة مع شركاء تابعين لجهات خارجية موثوقة على سبيل المثال (الجهات الحكومية , البنوك)",
            "لإرسال رسائل إلى البريد الإلكتروني أو رسائل قصيرة، إشعارات للمستخدمين عبر الموقع أو التطبيق أو غيرها من الوسائل المتاحة لتقديم كافة الخدمات، والإجابة على جميع الاستفسارات والطلبات، وغيرها من الأسئلة.",
            "نشارك المعلومات مع جهات تنفيذ القانون أو استجابةً للطلبات القانونية التي نتلقاه",
            "التسجيل التلقائي لدخول البيانات.",
            "لعمل إحصائيات وأبحاث وتقارير عقارية ومعاملات الدفع في الخدمات المتوفرة على الموقع.",
            "الشركاء الذين يستخدمون خدمات التحليل التي نوفرها.",
          ].map((point) => (
            <li key={point}>{point}</li>
          ))}
        </ul>
        بالتأكيد، أنت المسؤول الوحيد عن سرية كلمة المرور الخاصة بك، ومعلومات
        حسابك الشخصي. لذا نرجو منك الحرص على الحفاظ على هذه المعلومات لاسيما
        عندما تكون متصلاً بالإنترنت.
      </div>

      <h3>الملكية الفكرية</h3>
      <ol>
        {[
          "ما لم يذكر خلاف ذلك صراحة، فإن جميع محتويات المنصة تحمل حقوق التأليف والنشر، والعلامات التجارية، والمظهر التجاري و/أو الملكية الفكرية الأخرى التي نمتلكها، أو الخاضعة للرقابة أو المرخصة من قبلنا، أو إحدى الشركات التابعة لنا أو بواسطة أطراف ثالثة قامت بترخيص موادها لنا وهي محمية بموجب القوانين المعمول بها",
          "نحتفظ نحن، وموردونا والمرخصون لنا، بجميع حقوق الملكية الفكرية في كافة النصوص والبرامج، والمنتجات والعمليات، والتكنولوجيا، والمحتوى وغيرها من المواد التي تظهر على هذا الموقع. ولا يمنح الوصول إلى هذا الموقع ولا ينبغي أن يمنح أي شخص أي ترخيص تحت أي من حقوق الملكية الفكرية لنا أو لأي طرف ثالث. ويحظر أي استخدام لهذا الموقع أو محتوياته، بما في ذلك النسخ أو التخزين، كلياً أو جزئياً، خلاف استخدامك الشخصي وغير التجاري، دون إذن منا",
          "إن الأسماء والشعارات وجميع ما يتصل بها من منتجات وأسماء الخدمات، وتصميم العلامات والشعارات هي علامات تجارية أو علامات خدمة لنا أو للمرخصين لنا. ولا يجوز منح أي ترخيص للعلامة التجارية أو علامة الخدمة فيما يتعلق بالمواد الواردة في هذا الموقع.",
          "الوصول إلى هذا الموقع لا يخول أي شخص استخدام أي اسم، أو شعار أو علامة في أي صورة من الصور دون موافقة شركة مباشر.",
        ].map((point) => (
          <li key={point}>{point}</li>
        ))}
      </ol>
      <h3>التعويض</h3>
      <p>
        تقرون تعويضنا والشركات التابعة لنا (وموظفينا والوكلاء، والشركاء
        والموظفين) وإبراء ذمتنا من جميع الخسائر، والمسؤولية، والمطالبات، بما في
        ذلك أتعاب المحاماة المعقولة، والتي تنشأ من استخدامكم ووصولكم إلى موقعنا
        على الإنترنت أو تقديم مساهمات مخالفة للشروط و/او انتهاك حقوق الطبع
        والنشر والعلامات التجارية، وبراءات الاختراع والدعاية وقواعد البيانات أو
        غيرها من حقوق الملكية الفكرية
      </p>
      <h3>السياسة الأمنية لمنصة مباشر</h3>
      <p>
        تلتزم شركة مباشر باتفاقية الاستخدام و خصوصية الاستخدام ولكننا لسنا طرفا
        في أي خلاف أو قضايا تنشأ بين المستخدمين لمخالفة أحدهما أو كلاهما اتفاقية
        الاستخدام إلا أنها تسعى لتعزيز الجانب الأمني في الموقع وذلك للحد والقضاء
        على التعديات التي يقوم بها بعض من مستخدمي الموقع بشكل يخالف اتفاقية ،
        سياسة ، خصوصية وشروط الاستخدام وذلك تحقيقا لنزاهة البيع والشراء ومحاربة
        النصب والاحتيال والغش والخداع وإتباع للقوانين والتشريعات والتنظيمات
        المتبعة في المملكة العربية السعودية وبذلك فإنه يحق لشركة مباشر اتخاذ
        الإجراء اللازم تجاه أي فرد أو مؤسسة أو شركة خالفت اتفاقية استخدام شركة
        مباشر علما أنه قد يصل الإجراء إلى الملاحقة القانونية والقضائية أمام
        الجهة ذات العلاقة. ونورد هنا المخالفات الشائعة والإجراء اللازم تجاهها
      </p>
      <ol>
        <li>
          تعرض العميل لعملية نصب واحتيال من قبل طرف آخر:
          <ol>
            {[
              "على العميل التواصل على الايميل التالي: info@mobasher.sa",
              "ستقوم إدارة الموقع بمراجعة الشكوى والتحري حول العميل المدعى عليه وسيتم اتخاذ الإجراء اللازم في حقه في حال تم التأكد بأنه خالف اتفاقية الاستخدام. كما سيتم توثيق وأرشفة الواقعة للرجوع لها في أي وقت.",
              "على العميل الذي لحق به الضرر التوجه للجهات ذات العلاقة لتقديم الشكوى.",
              "لن تكون شركة مباشر طرف في مثل هذه القضايا ولا تتحمل أي مسئولية ولكننا سنقدم ما تم توثيقه للجهات المختصة في حال تم طلب ذلك.",
            ].map((point) => (
              <li key={point}>{point}</li>
            ))}
          </ol>
        </li>
        <li>
          التعدي على سياسة أو سيادة الدولة: سيتم توثيق الواقعة وحذف الإعلان
          وإيقاف عضوية المعلن وإبلاغ الجهات المختصة بذلك.
        </li>
        <li>
          في حال بيع أي من السلع الممنوعة: سيتم توثيق وأرشفة الإعلان وحذفه
          وإيقاف العضو وإبلاغ الجهات ذات الاختصاص
        </li>
        <li>
          في حال قام فرد أو مؤسسة أو شركة بطريق غير مشروعة (كاختراق أو استخدام
          وسائل تجميع البيانات غير المشروعة أو أي وسيلة كانت) بهدف الوصول إلى
          محتوى الموقع أو برمجة الموقع أو قواعد البيانات الخاصة بالموقع أو
          المعلومات والبيانات التي تخص عملاء الموقع, فإن شركة مباشر ستتوجه
          للجهات المختصة لمقاضاة الطرف الآخر بدعوى الاختراق الإلكتروني وارتكاب
          جريمة مخالفة أنظمة الجرائم المعلوماتية.
        </li>
        <li>
          التعدي على حقوق الملكية، أو الفكرية، أو براءة الاختراع لطرف ثالث:
          <ol>
            {[
              "إن كنت تعتقد بأنه تم التعدي على حق من حقوقك فعليك التواصل لرفع شكوى على الايميل التالي : info@mobasher.sa وتقديم ما يثبت أحقيتك في الإعلان المنشور",
              "ستقوم شركة مباشر بمراجعة الشكوى ودراستها والتأكد من صحتها وستتخذ الإجراء اللازم بحسب النتيجة كما أنه سيتم توثيق وأرشفة الشكوى للرجوع لها في أي وقت.",
              "عليك التوجه للجهات ذات الاختصاص في حال رغبتك في مقاضاة الطرف الذي اعتدى على حقوقك. علما أن شركة مباشر لن تكون طرف في القضية وليست مسئولا عنها ولا نتحمل أي مسئولية ولكننا سنقدم ما تم توثيقه في حال تم الطلب من الجهات المختصة.",
              "الإزعاج: عند تعرض عميل ما للإزعاج من قبل عميل آخر فعليه إشعار الإدارة بذلك وسيتم مراجعة الشكوى واتخاذ الإجراء المناسب حيال ذلك.",
            ].map((point) => (
              <li key={point}>{point}</li>
            ))}
          </ol>
        </li>
      </ol>
      <h3>التعديلات على سياسة سرية وخصوصية المعلومات</h3>
      <p>
        نحتفظ بالحق في تعديل بنود وشروط سياسة سرية وخصوصية المعلومات إن لزم
        الأمر ومتى كان ذلك ملائماً. سيتم تنفيذ التعديلات هنا او على صفحة
        الرئيسية وسيتم بصفة مستمرة إخطارك بالبيانات التي حصلنا عليها, وكيف
        سنستخدمها والجهة التي سنقوم بتزويدها بهذه البيانات.
      </p>
    </>
  );
}
