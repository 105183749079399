import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { ValidateEmail, IsNumerical } from "../../../helpers/Validate";
import questions from "./questions";
import FaqAccordion from "./FaqAccordion";

function FaqWrap() {
  const [submitting, setSubmitting] = useState(false);
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(false);
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [subject, setSubject] = useState("");
  const [subjectError, setSubjectError] = useState(false);
  const [message, setMessage] = useState("");
  const [messageError, setMessageError] = useState(false);
  const handelSend = async (e) => {
    //e.preventDefault();

    // بداية التحقق
    if (name.trim() === "") {
      setNameError(true);
    }
    if (email.trim() === "") {
      setEmailError(true);
    }
    if (phone.trim() === "") {
      setPhoneError(true);
    }
    if (subject.trim() === "") {
      setSubjectError(true);
    }
    if (message.trim() === "") {
      setMessageError(true);
    }
    if (
      name.trim() === "" ||
      email.trim() === "" ||
      phone.trim() === "" ||
      subject.trim() === "" ||
      message.trim() === ""
    ) {
      return toast.error("جميع الحقول مطلوبة");
    }
    if (!ValidateEmail(email.trim())) {
      setEmailError(true);
      return toast.error("الرجاء ادخال بريد الكتروني صحيح");
    }
    if (!IsNumerical(phone.trim()) || phone.trim().length !== 10) {
      setPhoneError(true);
      return toast.error("رقم الجوال يجب ان يكون 10 ارقام");
    }

    // نهاية التحقق
    const url = process.env.REACT_APP_BACKEND_HTTP + "send-email";
    const data = {
      to: "cs.moobasher@gmail.com",
      subject: "سؤال - الاسئلة الشائعة",
      html: `<p>الاسم : ${name} </p><p>الجوال : ${phone} </p><p>البريد الالكتروني : ${email} </p><p>عنوان الرسالة : ${subject}</p><p>الرسالة : ${message}</p>`,
    };
    try {
      setSubmitting(true);
      await axios.post(url, data);
    } catch (error) {}
    // finally
    setSubmitting(false);
    setName("");
    setPhone("");
    setEmail("");
    setSubject("");
    setMessage("");
    return toast.success("تم ارسال سؤالك بنجاح وسنتواصل معك في اقرب وقت");
  };
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={true}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="faq-section pt-120 pb-120">
        <div className="container">
          <div className="row d-flex justify-content-center gy-5">
            <div className="col-lg-4 col-md-12 order-lg-1 order-2">
              <div
                className="faq-form-area wow fadeInDown"
                data-wow-duration="1.5s"
                data-wow-delay=".2s"
              >
                <h5>لا تتردد في طرح أي سؤال</h5>

                <div className="faq-form">
                  <div className="form-inner">
                    <label>الاسم بالكامل *</label>
                    <input
                      className={nameError ? "border-danger" : ""}
                      type="text"
                      placeholder="اكتب اسمك كامل"
                      value={name}
                      onChange={(e) => {
                        setNameError(false);
                        setName(e.target.value);
                      }}
                    />
                  </div>
                  <div className="form-inner">
                    <label>رقم الجوال *</label>
                    <input
                      className={phoneError ? "border-danger" : ""}
                      type="text"
                      placeholder="الجوال :0555555555"
                      value={phone}
                      onChange={(e) => {
                        setPhoneError(false);
                        setPhone(e.target.value);
                      }}
                    />
                  </div>
                  <div className="form-inner">
                    <label>البريد الالكتروني *</label>
                    <input
                      className={emailError ? "border-danger" : ""}
                      type="text"
                      placeholder="اكتب بريدك الالكتروني"
                      value={email}
                      onChange={(e) => {
                        setEmailError(false);
                        setEmail(e.target.value.toLowerCase());
                      }}
                    />
                  </div>
                  <div className="form-inner">
                    <label>عنوان الرسالة *</label>
                    <input
                      className={subjectError ? "border-danger" : ""}
                      type="text"
                      placeholder="اكتب عنوان الرسالة"
                      value={subject}
                      onChange={(e) => {
                        setSubjectError(false);
                        setSubject(e.target.value);
                      }}
                    />
                  </div>
                  <div className="form-inner">
                    <label>السؤال *</label>
                    <textarea
                      className={messageError ? "border-danger" : ""}
                      placeholder="اكتب سؤالك"
                      rows={5}
                      onChange={(e) => {
                        setMessageError(false);
                        setMessage(e.target.value);
                      }}
                      defaultValue=""
                      value={message}
                    />
                  </div>
                  <button
                    onClick={(e) => handelSend(e)}
                    className="eg-btn btn--primary btn--md form--btn"
                    disabled={submitting}
                  >
                    ارسال سؤال
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-md-12 text-center order-lg-2 order-1">
              <h2 className="section-title3"> الاسئلة الشائعة </h2>
              <div
                className="faq-wrap wow fadeInUp"
                data-wow-duration="1.5s"
                data-wow-delay=".2s"
              >
                <div className="accordion" id="accordionExample">
                  <FaqAccordion title="ماذا تقدم لي منصة مباشر للمزادات إذا كنت وكيل بيع من انفاذ او خاص ؟">
                    <ul style={{ listStyleType: "square" }}>
                      <li>
                        انقر على شكل (
                        <img
                          src={
                            process.env.PUBLIC_URL + "/images/icons/user.svg"
                          }
                          alt="user-icon"
                        />
                        ) في أعلى الشاشة ثم انقر على المالية ومن ثم انقر على
                        محفظتي.
                      </li>
                      <li>اضغط على عمليات الشحن عن طريق سداد .</li>
                      <li>تظهر لك الفواتير التي تم تسديدها في أسفل الشاشة.</li>
                      <li>انقر على ايقونة اختر ثم اضغط على يمكن استردادها.</li>
                      <li style={{ wordSpacing: "2px" }}>
                        الشرح المرئي لآلية شحن المحفظة استرداد مبلغ التأمين أنقر
                        على الرابط :{" "}
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://youtu.be/Syx86zaSlhA"
                        >
                          https://youtu.be/Syx86zaSlhA
                        </a>
                      </li>
                    </ul>
                  </FaqAccordion>
                  <FaqAccordion title="كيف اضيف حسابي البنكي؟">
                    <li>
                      انقر على شكل (
                      <img
                        src={process.env.PUBLIC_URL + "/images/icons/user.svg"}
                        alt="user-icon"
                      />
                      ) في أعلى الشاشة ثم انقر على حسابي
                    </li>
                    <li>توجه للحسابات البنكية وقم بإضافة حسابك البنكي.</li>
                    <li>
                      نرجو ارفاق شهادة الآيبان في المرفقات أو إضافة بطاقة
                      الآيبان وتظهر بها كافة البيانات بشكل واضح.
                    </li>
                  </FaqAccordion>
                  <FaqAccordion title="ماذا تقدم لي منصة مباشر للمزادات إذا كنت وكيل بيع من انفاذ او خاص ؟">
                    <p style={{ marginBottom: "5px" }}>
                      توفر لك منصة مباشر فريق عمل تقني وفني لخدمتك بإمكانيات
                      عالية.
                    </p>
                    <p>
                      بالإضافة الى فتح نافذة خاصه تحمل الشعار الخاص بك، وتمكنك
                      من التحكم ببياناتك، وسهولة الوصول الى الخدمات المالية
                      والمزادات والمشاركين والمنتجات وكافة الخدمات التي تود
                      الاستفسار عنها.
                    </p>
                  </FaqAccordion>
                  <FaqAccordion title=" كيف أسجل في منصة مباشر ؟">
                    <li>
                      انقر على شكل (
                      <img
                        src={process.env.PUBLIC_URL + "/images/icons/user.svg"}
                        alt="user-icon"
                      />
                      ) يسار الشاشة.
                    </li>
                    <li>إنشاء حساب أسفل الصفحة.</li>
                    <li>اختيار نوع العضوية فرد أو شركة.</li>
                    <li>
                      استكمل البيانات ومن ثم الموافقة على الشروط والأحكام.
                    </li>
                    <li>
                      انقر على ايقونة إنشاء حساب وبذلك أصبح الان لديك حساب
                      مشتري.
                    </li>
                  </FaqAccordion>
                  <FaqAccordion title="طريقة إخفاء الاسم ؟">
                    <li>
                      انقر على ايقونة (
                      <img
                        src={process.env.PUBLIC_URL + "/images/icons/user.svg"}
                        alt="user-icon"
                      />
                      ) يسار الشاشة.
                    </li>
                    <li>النقر على الإعدادات.</li>
                    <li>
                      تظهر لك صفحة ظهورك في المزادات : أخر أربع أرقام من الهوية،
                      أو الاسم الكامل، أو الظهور برقم المزايد (رقم عشوائي يمنحك
                      إياه المزاد).
                    </li>
                  </FaqAccordion>
                  {questions.map(({ title, answer }) => (
                    <FaqAccordion title={title}>
                      {typeof answer === "object" ? (
                        <ul style={{ listStyleType: "square" }}>
                          {answer.map((point) => (
                            <li key={point}>{point}</li>
                          ))}
                        </ul>
                      ) : (
                        answer
                      )}
                    </FaqAccordion>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FaqWrap;
