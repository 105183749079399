import React from "react";

import Footer from "../../common/Footer";
import Header from "../../common/Header";
import WhoWeAreArea from "./WhoWeAreArea";

function About() {
  return (
    <>
      <Header />
      <WhoWeAreArea />
      <Footer />
    </>
  );
}

export default About;
