import React from "react";

function HeroBanner() {
  return (
    <>
      {/* <div className="hero-area hero-style-one">
        <div className="hero-main-wrapper position-relative h-100">
          <div className="swiper-wrapper">
            <div className="slider-bg-1">
              <div className="container">
                <div className="row d-flex justify-content-center align-items-center">
                  <div className="col-xl-8 col-lg-10"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="hero-one-pagination d-flex justify-content-center flex-column align-items-center gap-3" />
        </div>
      </div> */}
      <section id="hero" className="py-3 pb-5 py-md-5 position-relative">
        <div className="container text-center text-white pb-5">
          <div className="row my-5">
            <div className="col col-md-10 offset-md-1 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3">
              <h2 className="mb-4">
                الشركة الرائدة في إدارة مزادات العقارات والسيارات السعودية
                إلكترونياً
              </h2>
              <p className="lead">
                نسعى لتطوير المزادات الإلكترونية من خلال تقديم حلول تقنية مبتكرة
                وآمنة وبشفافية عالية.
              </p>
            </div>
          </div>
        </div>
        <svg viewBox="0 0 100 10" preserveAspectRatio="none" className="curve">
          <path d="M0,0 C35,5 65,5 100,0 L100,10 L0,10 Z" fill="white"></path>
        </svg>
        <div className="btn-group mx-auto position-absolute bottom-0 start-50 translate-middle-x px-3">
          <a
            href="https://re.mobasher.sa"
            className="btn btn-light border py-4"
            aria-current="page"
          >
            <img
              className="me-2"
              src={process.env.PUBLIC_URL + "images/icon-realestate-green.svg"}
              width="32"
              height="auto"
              alt="عقارات"
              title="عقارات"
            />
            <p className="mb-0 h5 mt-2">مزادات العقارات</p>
          </a>
          <a
            href="https://re.mobasher.sa?cat=motor"
            className="btn btn-light border py-4"
          >
            <img
              className="me-2"
              src={process.env.PUBLIC_URL + "images/icon-car-green.svg"}
              width="32"
              height="auto"
              alt="سيارات"
              title="سيارات"
            />
            <p className="mb-0 h5 mt-2">مزادات السيارات</p>
          </a>
        </div>
      </section>
    </>
  );
}

export default HeroBanner;
