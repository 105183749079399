const questions = [
  {
    title: "كيف تتم طريقة شحن المحفظة لمزادات العقارات ؟",
    answer: [
      "يتم شحن المحفظة عن طريق التوجه للعقار المراد المشاركة به.",
      "ثم الضغط على الاشتراك في المزاد او سعر الافتتاح أو أعلى مزايدة.",
      "تظهر لك شاشة يوجد بها رقم للتحقق من نفاذ الوطني توجه الى تطبيق نفاذ واضغط على الرقم.",
      "بعد التحقق من نفاذ توجه للموقع واضغط مرة أخرى على الاشتراك في المزاد او سعر الافتتاح أو أعلى مزايدة.",
      "تظهر لك شاشة الاشتراك في المزاد اضغط على اشترك الان.",
      "ثم تظهر لك ايقونة انشاء فاتورة سداد اضغط على انشاء فاتورة ليتم دفع الفاتورة عن طريق البنك ورقمنا 903 إيداعات ويتم تسديد الفاتورة مثل فواتير الكهرباء.",
    ],
  },
  {
    title: "ماهي آلية الاشتراك في المزاد ؟",
    answer: [
      "بعد القيام بسداد الفاتورة توجه مرة أخرى للموقع واضغط على الاشتراك في المزاد او سعر الافتتاح أو أعلى مزايدة.",
      "ثم اضغط على ايقونة خصم المبلغ من رصيد المحفظة للاشتراك في العقار.",
      "تظهر لك ايقونة سؤال هل انت اصيل ام وكيل اضغط على اصيل إذا كنت اصيل وإذا كنت وكيل يتم ارفاق الوكالة ثم تصبح مشارك في العقار.",
    ],
  },
  {
    title: "كيف يتم أبلاغي في حال ترسية المزاد ؟",
    answer: [
      "يصل محضر الترسية شامل أجمالي السعي وبالإضافة الى قيمة المزايدة مخصوم منها مبلغ التأمين.",
      "بعد وصول محضر الترسية يتم دفع المبلغ المتبقي خلال 3 أيام.",
      "دفع المبلغ المتبقي من خلال التواصل مع وكيل البيع او انفاذ.",
    ],
  },
  {
    title: "كيف تحسب نسبة الوكيل ؟",
    answer:
      "تسجل دخولك الى الموقع بصفة وكيل وليس اصيل ومن ثم تتواصل مع وكيل البيع لإبلاغه بالنسبة الخاصة بك. ",
  },
  {
    title: "هل منصة مباشر معتمدة ؟",
    answer:
      "نعم؛ منصة مباشر للمزادات الإلكترونية معتمدة من قبل الهيئة العامة للعقار ومركز اسناد والتصفية (إنفاذ)، وأيضاً إيداعات ونافذ.",
  },
  {
    title: "هل إذا توقف المزاد يتم بيع المركبة مباشرة لأعلى مزايد ؟",
    answer: "لا؛ جميع المزادات تخضع لقرار لجنة البيع خلال 48 ساعة كحد أقصى.",
  },
  {
    title: "هل يمكن تقسيم الفاتورة عند السداد ؟",
    answer: "لا, ولكن يمكنك السداد من أي حساب بنكي لديك.",
  },
  {
    title: "هل توجد معلومات إضافية عن المركبة غير الموجودة في المنصة ؟",
    answer: "لا, ولكن يمكن للعميل التوجه لمعاينة المركبة.",
  },
  {
    title: "كيف يمكنني معاينة المركبة ؟",
    answer: [
      "التوجه للمزاد المراد المزايدة فيه.",
      ' ثم على المركبة التي ترغب بشرائها على اليسار كلمة "عرض".',
      "ثم يظهر لك خيار العنوان وهو عبارة عن خريطة قوقل ماب بمكان المستودع.",
    ],
  },
  {
    title: "هل السيارة تعمل ؟",
    answer: "لا نعلم, لكن بإمكانك التوجه لموقع المعاينة للسيارة.",
  },
  {
    title: "هل يمكن التنازل عن المركبة للغير؟",
    answer: "نعم، بموجب وكالة من ناجز تثبت التنازل.",
  },
  {
    title: "هل يمكن المزاودة على النفس أكثر من مرة؟",
    answer:
      "نعم؛ النظام يتيح لك المزاودة على نفسك أكثر من مرة وذلك لوجود حد أدنى للبيع.",
  },
  {
    title: "ماهو الإجراء المُتبع في حال المزاودة بالخطأ؟",
    answer: "لا يوجد مزايدة تتم بالخطأ, المزاد على رقابة عالية.",
  },
];

export default questions;

/* Old FAQ

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        ما هي منصة مباشر المزادات ؟
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          هي منصة لإدارة وتنظيم وتشغيل المزادات تقنيا وتعمل
                          كهمزة وصل بين البائعين والمشترين من خلال المزادات
                          العلنية ‘المباشرة’ والمزادات الزمنية ‘الإلكترونية’ دون
                          الحاجة للحضور لمقر المزاد وتمكن البائعين من كافة
                          الفئات بما فيهم المنشآت الرسمية من القطاع الخاص
                          والقطاع العام من عرض وبيع ما لديهم من عروض وسلع متعددة
                          الفئات بأنواعها المختلفة والسماح للراغبين بالشراء
                          بالمزايدة بحلول تقنية متطورة وآمنة وبخدمات متميزة
                          وبشفافية عالية
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        من هم البائعين في منصة مباشر؟
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          المنشآت الرسمية من القطاعات الخاصة والعامة والمختلطة
                          مثل :-
                        </p>
                        <ul className="about-list">
                          <li className="text-muted">
                            المنشآت التجارية (شركات، مؤسسات الخ..)
                          </li>
                          <li className="text-muted">
                            جهات حكومية وشبه حكومية والمختلطة.
                          </li>
                          <li className="text-muted">شركات تنظيم المزادات.</li>
                        </ul>
                        <p>كما يوجد خطة واعدة عن الإتاحة للأفراد.</p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        هل يمكنني كفرد انا بيع ما لدي في منصة مباشر للمزادات ؟
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          لايمكن للأفراد البيع في منصة مباشر للمزادات حاليًا.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFour">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                      >
                        ما هي أهم مميزات منصة مباشر للمزادات ؟
                      </button>
                    </h2>
                    <div
                      id="collapseFour"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingFour"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <ul className="about-list">
                          <li>سهولةالمشاركة دون التقيد بالموقع الجغرافي</li>
                          <li>مرتبطة بقنوات الدفع الإلكتروني</li>
                          <li>
                            أمنة تدعم التحقق من المشتري عبر نظام نافذ و منصة
                            نفاذ
                          </li>
                          <li>
                            نظام يدعم استقبال أكثر من نصف مليون شخص للمزايدة في
                            الوقت ذاته
                          </li>
                          <li>
                            قبول المزايدة في وقت قياسي"أقل من ثلاثة أجزاء من
                            الثانية"
                          </li>
                          <li>مزايدة تتم أليًا مع تحديد أعلى رقم للشراء</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFive">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFive"
                        aria-expanded="false"
                        aria-controls="collapseFive"
                      >
                        ما هوالمزاد الإلكتروني (الزمني) ؟
                      </button>
                    </h2>
                    <div
                      id="collapseFive"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingFive"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          هو مزاد إلكتروني محدد المدة يتبع العد التنازلي ويمكن
                          المشتركين بالمزايدة اون لاين عبر الويب او التطبيق وذلك
                          من وقت البدء حتى انتهاء الوقت المحدد لكل مزاد سلفا
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingSix">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSix"
                        aria-expanded="false"
                        aria-controls="collapseSix"
                      >
                        ما هوالمزاد الهجين (المباشر) ؟
                      </button>
                    </h2>
                    <div
                      id="collapseSix"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingSix"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          هو مزاد علني يتم نقله عبر بث حي مباشر من الموقع
                          المُقام عليه المزاد الفعلي لتمكين المشتركين المحليين
                          بالمزايدة على أرض الواقع وكذلك يمكن المزايدين الاون
                          لاين بالمزايدة الفعلية في نفس اللحظة عن ُبعد من أي
                          مكان وبعدد لا محدود
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingSeven">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSeven"
                        aria-expanded="false"
                        aria-controls="collapseSeven"
                      >
                        هل المزايدة في المنصة آمنة؟
                      </button>
                    </h2>
                    <div
                      id="collapseSeven"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingSeven"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          عملية المزايدة في المنصة موثوقة وآمنة للبائع والمشتري
                          و تتبع نهج قوانين إقامة المزادات في المملكة العربية
                          السعودية
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingSeven">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSeven"
                        aria-expanded="false"
                        aria-controls="collapseSeven"
                      >
                        ما هو المزايد الآلي؟
                      </button>
                    </h2>
                    <div
                      id="collapseSeven"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingSeven"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          المزايد الآلي يمكنك من وضع الحد الأقصى للمبلغ الذي
                          ترغب دفعه لربح المزاد ويقوم المزايد الآلي بالمزايدة
                          تلقائي نيابة عنك وعلى مسئوليتك الكاملة وتكون المزايدة
                          بنفس سعر التزايد التدريجي الموضح لكل مزاد.
                        </p>
                        <p>قواعد الخدمة : -</p>
                        <ul className="about-list">
                          <li>
                            تعمل خدمة المزايد الآلي في جميع المزادات الزمنية
                            والمباشرة.
                          </li>
                          <li>
                            مبلغ المزايدة الآلية الخاص بك يكون غير ظاهر للبائعين
                            أو موظفي المزاد أو المزايدين الآخرين بمنطقية وسرية
                            تامة.{" "}
                          </li>
                          <li>
                            لا يمكنك تعديل أو تغيير أو حذف مبلغ المزايد الآلي ان
                            كنت صاحب أعلى مزايدة.
                          </li>
                          <li>
                            في المزادات المباشرة تستطيع استخدام المزايد الآلي مع
                            إمكانية التعديل أو التغيير أو الحذف وذلك قبل بدء
                            المزاد.
                          </li>
                          <li>
                            إذا ظهرت ملاحظات جديدة على حالة السلعةوالتي لمُ توضح
                            مسبقًا وقت المزادالمباشر فسيتم الغاءالمزايد الآلي
                            المُقدم منك لنفس المنتج واشعارك بذلك، وهذا من أجل
                            حمايتك ولضمان سلامة وأمن إجراءات المزايدات.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

*/
