import React from "react";
import { Link } from "react-router-dom";

function WhoWeAreArea() {
  return (
    <>
      {/* <div className="about-section pt-120 pb-120 overflow-hidden">
        <img
          src={process.env.PUBLIC_URL + "/images/bg/section-bg.png"}
          className="img-fluid section-bg-top"
          alt="section-bg"
        />
        <div className="container">
          <div className="row d-flex justify-content-center g-4">
            <div className="col-lg-6 col-md-10">
              <div className="about-img-area d-flex align-items-center justify-content-center mb-5">
                <img
                  src={process.env.PUBLIC_URL + "/plain-logo.svg"}
                  className="img-fluid about-img wow fadeInUp w-75 position-absolute"
                  data-wow-duration="1.5s"
                  data-wow-delay=".2s"
                  alt="about-img"
                />

                <img
                  src={process.env.PUBLIC_URL + "/images/bg/about-vector.png"}
                  className="img-fluid about-vector position-relative start-0"
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-10">
              <div
                className="about-content wow fadeInDown"
                data-wow-duration="1.5s"
                data-wow-delay=".2s"
              >
                <h2>نسعى لتطوير المزادات إلكترونياً وتحقيق رؤية 2030</h2>
                <p className="para">
                  منصة مباشر هي لإدارة وتنظيم وتشغيل المزادات إلكترونياً وتعمل
                  كحلقة وصل بين البائعين والمشترين، من خلال المزادات العلنية
                  المنقولة نقل مباشر والمزادات الزمنية الإلكترونية دون الحاجة
                  للحضور إلى مقر المزاد، وتمكن البائعين من كافة الفئات من القطاع
                  الحكومي والقطاع الخاص والأفراد من عرض وبيع ما لديهم من عروض
                  وسلع بأنواعها المختلفة، والسماح للراغبين بالشراء بالمزايدة
                  بسهولة وبحلول تقنية متطورة وآمنة وبخدمات متميزة وبشفافية
                  عالية.
                </p>

                <h2>مزايا مزادات مباشر الإلكترونية :</h2>
                <ul className="about-list">
                  <li>
                    <Link to={"#"}>
                      سهولة المشاركة دون التقيد بالموقع الجغرافي.
                    </Link>
                  </li>
                  <li>
                    <Link to={"#"}>مرتبطة بقنوات الدفع الإلكتروني.</Link>
                  </li>
                  <li>
                    <Link to={"#"}>
                      أمنة تدعم التحقق من المشتري عبر نظام نافذ و منصة نفاذ.
                    </Link>
                  </li>
                  <li>
                    <Link to={"#"}>
                      نظام يدعم استقبال أرقام ضخمة من المزايدات في ذات الوقت.
                    </Link>
                  </li>
                  <li>
                    <Link to={"#"}>
                      قبول المزايدة في وقت قياسي "أقل من أجزاء الثانية".
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <section id="about" className="py-3 py-md-5">
        <div className="container pb-5">
          <div className="row">
            <div className="col-md-5 col-xl-5 px-5 px-md-4 mb-4 mb-md-0 order-md-last">
              <img
                src={process.env.PUBLIC_URL + "images/buildings.webp"}
                className="img-fluid"
                alt="مزادات السعودية"
                title="مزادات السعودية"
                height="auto"
                width="auto"
              />
            </div>
            <div className="col-md-7 col-xl-5 offset-xl-1 px-4">
              <div className="d-flex flex-column justify-content-end align-items-start h-100">
                <p className="mb-4 py-2 border rounded-pill px-4 text-mobasher">
                  المزادات الإلكترونية
                </p>
                <h2 className="mb-3 pe-5">
                  نسعى لتطوير المزادات إلكترونياً وتحقيق رؤية 2030
                </h2>
                <p className="mb-5">
                  منصة مباشر هي منصة إلكترونية لإدارة وتنظيم المزادات، تربط بين
                  البائعين والمشترين عبر مزادات علنية مباشرة وزمنية إلكترونية
                  دون الحاجة للحضور الشخصي. تتيح للبائعين من جميع الفئات عرض
                  وبيع مختلف السلع، وتمكن المشترين من المزايدة بسهولة باستخدام
                  تقنيات متطورة وآمنة، مع ضمان الشفافية وجودة الخدمات.
                </p>
                <h3 className="mb-3">مزايا مزادات مباشر الإلكترونية:</h3>
                <ul className="mb-0">
                  <li>سهولة المشاركة دون التقيد بالموقع الجغرافي.</li>
                  <li>مرتبطة بقنوات الدفع الإلكتروني.</li>
                  <li>
                    أمنة تدعم التحقق من المشتري عبر نظام نافذ و منصة نفاذ.
                  </li>
                  <li>
                    نظام يدعم استقبال أرقام ضخمة من المزايدات في ذات الوقت.
                  </li>
                  <li>قبول المزايدة في وقت قياسي "أقل من أجزاء الثانية".</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default WhoWeAreArea;
