import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Autoplay,
  EffectFade,
  Navigation,
  Pagination,
} from "swiper";
import "swiper/css/autoplay";
SwiperCore.use([Pagination, Autoplay, EffectFade, Navigation]);
function Catagory() {
  const caragorySlider = {
    slidesPerView: 1,
    speed: 1000,
    spaceBetween: 20,
    loop: false,
    autoplay: false,
    roundLengths: true,
    navigation: {
      nextEl: ".category-prev1",
      prevEl: ".category-next1",
    },

    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      200: {
        slidesPerView: 2,
      },
    },
  };
  return (
    <>
      <div className="category-section ">
        <div className="container position-relative">
          <div className="row d-flex justify-content-center">
            <Swiper {...caragorySlider} className="swiper category1-slider">
              <div className="swiper-wrapper">
                <SwiperSlide className="swiper-slide">
                  <div className="website-link">
                    <a href={"https://re.mobasher.sa/?cat=motors"}>
                      <div className="d-flex flex-column align-items-center gap-4">
                        <img
                          className="rounded-circle"
                          width={120}
                          height={120}
                          alt="logo"
                          src={
                            process.env.PUBLIC_URL +
                            "/images/bg/car-auction.png"
                          }
                        />
                        <span>مزادات السيارات و المنقولات</span>
                      </div>
                    </a>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <div className="website-link">
                    <a href="https://re.mobasher.sa/">
                      <div className="d-flex flex-column align-items-center gap-4">
                        <img
                          className="rounded-circle"
                          width={120}
                          height={120}
                          alt="logo"
                          src={
                            process.env.PUBLIC_URL +
                            "/images/bg/real-estate-auction.png"
                          }
                        />
                        <span>مزادات العقارات</span>
                      </div>
                    </a>
                  </div>
                </SwiperSlide>
              </div>
            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
}

export default Catagory;
