import { useQuery } from "@apollo/client";
import React from "react";

import SwiperCore, { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { SELLERS_SLIDERS } from "../../../models/Queries";
SwiperCore.use([Navigation, Autoplay]);

function CategoryHome2() {
  const { data } = useQuery(SELLERS_SLIDERS, {
    variables: {
      sort: "order:asc",
    },
    fetchPolicy: "cache-first",
  });

  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  const categorySlider = {
    slidesPerView: 1,
    speed: 1000,
    spaceBetween: 30,
    loop: true,
    autoplay: true,
    roundLengths: true,
    pagination: false,
    navigation: {
      nextEl: ".category-prev2",
      prevEl: ".category-next2",
    },

    breakpoints: {
      280: {
        slidesPerView: 1,
      },
      380: {
        slidesPerView: 2,
      },
      540: {
        slidesPerView: 3,
      },
      576: {
        slidesPerView: 3,
      },
      768: {
        slidesPerView: 4,
      },
      992: {
        slidesPerView: 5,
      },
      1200: {
        slidesPerView: 6,
      },
      1400: {
        slidesPerView: 7,
      },
    },
  };
  return (
    <>
      <div className="category-section2 mt-4" id="category">
        <div className="container position-relative">
          <div className="row">
            <div className="col-12">
              <Swiper {...categorySlider} className="swiper category2-slider">
                <div className="swiper-wrapper">
                  {Array.isArray(data?.sellersliders) &&
                    data?.sellersliders.length > 0 &&
                    data?.sellersliders.map((item, index) =>
                      item.logo.url ? (
                        <SwiperSlide key={index} className="swiper-slide">
                          <div
                            onClick={() => window.open(item.link, "_blank")}
                            className="eg-card category-card2 fadeInDown"
                            data-wow-duration="1.5s"
                            data-wow-delay="0.2s"
                            style={{ cursor: "pointer" }}
                          >
                            <img
                              alt={item.name}
                              title={item.name}
                              src={item.logo.url}
                              style={{ width: "inherit" }}
                              width="auto"
                              height="auto"
                              loading="lazy"
                            />
                          </div>
                        </SwiperSlide>
                      ) : null
                    )}
                </div>
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CategoryHome2;
