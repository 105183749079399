import React from "react";
import CountUp from "react-countup";

function AboutUsCounter() {
  return (
    <>
      {/* <div className="about-us-counter pb-120">
        <div className="container">
          <div className="row g-4 d-flex justify-content-center">
            <div className="col-lg-3 col-md-6 col-sm-10 col-10">
              <div className="counter-single text-center d-flex flex-row hover-border1 wow fadeInDown" data-wow-duration="1.5s" data-wow-delay=".2s">
                <div className="counter-icon"> <img alt="images" src={process.env.PUBLIC_URL + "/images/icons/employee.svg"} /> </div>
                <div className="coundown d-flex flex-column">
                  <h3 className="odometer" data-odometer-final={5400}><CountUp end={210} delay={2} duration={5} /></h3>
                  <p>Happy Customer</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-10 col-10">
              <div className="counter-single text-center d-flex flex-row hover-border1 wow fadeInDown" data-wow-duration="1.5s" data-wow-delay=".4s">
                <div className="counter-icon"> <img alt="images" src={process.env.PUBLIC_URL + "/images/icons/review.svg"} /> </div>
                <div className="coundown d-flex flex-column">
                  <h3 className="odometer" data-odometer-final={1250}><CountUp end={250} delay={2} duration={5} /></h3>
                  <p>Good Reviews</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-10 col-10">
              <div className="counter-single text-center d-flex flex-row hover-border1 wow fadeInDown" data-wow-duration="1.5s" data-wow-delay=".4s">
                <div className="counter-icon"> <img alt="images" src={process.env.PUBLIC_URL + "/images/icons/smily.svg"} /> </div>
                <div className="coundown d-flex flex-column">
                  <h3 className="odometer" data-odometer-final={4250}><CountUp end={150} delay={2} duration={5} /></h3>
                  <p>Winner Customer</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-10 col-10">
              <div className="counter-single text-center d-flex flex-row hover-border1 wow fadeInDown" data-wow-duration="1.5s" data-wow-delay=".8s">
                <div className="counter-icon"> <img alt="images" src={process.env.PUBLIC_URL + "/images/icons/comment.svg"} /> </div>
                <div className="coundown d-flex flex-column">
                  <h3 className="odometer" data-odometer-final={500}><CountUp end={550} delay={2} duration={5} /></h3>
                  <p>New Comments</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <section id="add-auction" className="py-3 py-md-5">
        <div className="container py-5">
          <div className="position-relative">
            <div id="add-auction-card" className="text-white p-5">
              <div className="row my-lg-5">
                <div className="col-12 col-lg-5 text-center text-lg-start mb-4 mb-lg-0">
                  <h2 className="mb-3">لديك عقار أو سيارة للبيع؟</h2>
                  <p className="mb-4">
                    يمكنك إضافة عقارك أو سيارتك للبيع بالمزاد على منصتنا بكل
                    سهولة، وفريق الدعم لدينا جاهز لمساعدتك في أي وقت.
                  </p>
                  <a
                    href="https://re.mobasher.sa?action=add-auction"
                    className="btn btn-light rounded-pill px-5 py-3 text-mobasher fw-bold"
                  >
                    أضف مزادك الآن
                  </a>
                </div>
              </div>
              <img
                className="d-lg-none img-fluid"
                src={process.env.PUBLIC_URL + "images/views.webp"}
                alt="أضف مزادك الان"
                title="أضف مزادك الان"
                height="auto"
                width="auto"
              />
            </div>
            <img
              className="d-none d-lg-block position-absolute top-50 end-0 translate-middle-y me-4"
              src={process.env.PUBLIC_URL + "images/views.webp"}
              alt="أضف مزادك الان"
              title="أضف مزادك الان"
              height="auto"
              width="auto"
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default AboutUsCounter;
