import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { ValidateEmail, IsNumerical } from "../../../helpers/Validate";

const ContactWrapper = () => {
  const [submitting, setSubmitting] = useState(false);
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(false);
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [subject, setSubject] = useState("");
  const [subjectError, setSubjectError] = useState(false);
  const [message, setMessage] = useState("");
  const [messageError, setMessageError] = useState(false);

  const handelSend = async (e) => {
    //e.preventDefault();

    // بداية التحقق
    if (name.trim() === "") {
      setNameError(true);
    }
    if (email.trim() === "") {
      setEmailError(true);
    }
    if (phone.trim() === "") {
      setPhoneError(true);
    }
    if (subject.trim() === "") {
      setSubjectError(true);
    }
    if (message.trim() === "") {
      setMessageError(true);
    }
    if (
      name.trim() === "" ||
      email.trim() === "" ||
      phone.trim() === "" ||
      subject.trim() === "" ||
      message.trim() === ""
    ) {
      return toast.error("جميع الحقول مطلوبة");
    }
    if (!ValidateEmail(email.trim())) {
      setEmailError(true);
      return toast.error("الرجاء ادخال بريد الكتروني صحيح");
    }
    if (!IsNumerical(phone.trim()) || phone.trim().length !== 10) {
      setPhoneError(true);
      return toast.error("رقم الجوال يجب ان يكون 10 ارقام");
    }

    // نهاية التحقق
    const url = process.env.REACT_APP_BACKEND_HTTP + "send-email";
    const data = {
      to: "cs.moobasher@gmail.com",
      subject: "اتصل بنا",
      html: `<p>الاسم : ${name} </p><p>الجوال : ${phone} </p><p>البريد الالكتروني : ${email} </p><p>عنوان الرسالة : ${subject}</p><p>الرسالة : ${message}</p>`,
    };
    try {
      setSubmitting(true);
      await axios.post(url, data);
    } catch (error) {}
    // finally
    setSubmitting(false);
    setName("");
    setPhone("");
    setEmail("");
    setSubject("");
    setMessage("");
    return toast.success("تم ارسال سؤالك بنجاح وسنتواصل معك في اقرب وقت");
  };
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={true}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="contact-section pt-120 pb-120">
        <img
          alt="images"
          src={process.env.PUBLIC_URL + "/images/bg/section-bg.png"}
          className="img-fluid section-bg-top"
        />
        <img
          alt="images"
          src={process.env.PUBLIC_URL + "/images/bg/section-bg.png"}
          className="img-fluid section-bg-bottom"
        />
        <div className="container">
          <div className="row pb-120 mb-70 g-4 d-flex justify-content-center">
            <div className="col-lg-4 col-md-6 col-sm-8">
              <div
                className="contact-signle hover-border1 d-flex flex-row align-items-center wow fadeInDown"
                data-wow-duration="1.5s"
                data-wow-delay=".2s"
              >
                <div className="icon">
                  <i className="bi bi-geo-alt" />
                </div>
                <div className="text">
                  <h4>العنوان</h4>
                  <p>13515 طريق الامام سعود بن فيصل - العقيق - الرياض</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-8">
              <div
                className="contact-signle hover-border1 d-flex flex-row align-items-center wow fadeInDown"
                data-wow-duration="1.5s"
                data-wow-delay=".4s"
              >
                <div className="icon">
                  <i className="bx bx-phone-call" />
                </div>
                <div className="text">
                  <h4>الهاتف</h4>
                  <a
                    style={{ direction: "ltr", float: "left" }}
                    href="tel:+966 055 880 3411"
                  >
                    +966 055 880 3411
                  </a>
                  <a
                    style={{ direction: "ltr", float: "left" }}
                    href="tel:+966 920023225"
                  >
                    +966 920023225
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-8">
              <div
                className="contact-signle hover-border1 d-flex flex-row align-items-center wow fadeInDown"
                data-wow-duration="1.5s"
                data-wow-delay=".6s"
              >
                <div className="icon">
                  <i className="bx bx-envelope" />
                </div>
                <div className="text">
                  <h4>البريد الالكتروني</h4>
                  <a href="mailto:info@mobasher.sa">info@mobasher.sa</a>
                  <a href="mailto:info@moobasher.com">info@moobasher.com</a>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-4">
            <div className="col-lg-6">
              <div
                className="form-wrapper wow fadeInDown"
                data-wow-duration="1.5s"
                data-wow-delay=".2s"
              >
                <div className="form-title2">
                  <h3>تواصل معنا</h3>
                  <p className="para">
                    لا تتردد في طرح أي سؤال أو شاركنا برأيك
                  </p>
                </div>

                <div className="row">
                  <div className="col-xl-6 col-lg-12 col-md-6">
                    <div className="form-inner">
                      <input
                        className={nameError ? "border-danger" : ""}
                        type="text"
                        placeholder="الاسم :"
                        value={name}
                        onChange={(e) => {
                          setNameError(false);
                          setName(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-12 col-md-6">
                    <div className="form-inner">
                      <input
                        className={emailError ? "border-danger" : ""}
                        type="email"
                        placeholder="البريد الالكتروني :"
                        value={email}
                        onChange={(e) => {
                          setEmailError(false);
                          setEmail(e.target.value.toLowerCase());
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-12 col-md-6">
                    <div className="form-inner">
                      <input
                        className={phoneError ? "border-danger" : ""}
                        type="text"
                        placeholder="الجوال :0555555555"
                        value={phone}
                        onChange={(e) => {
                          setPhoneError(false);
                          setPhone(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-12 col-md-6">
                    <div className="form-inner">
                      <input
                        className={subjectError ? "border-danger" : ""}
                        type="text"
                        placeholder="عنوان الرسالة :"
                        value={subject}
                        onChange={(e) => {
                          setSubjectError(false);
                          setSubject(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <textarea
                      name="message"
                      className={messageError ? "border-danger" : ""}
                      placeholder="نص الرسالة :"
                      rows={12}
                      onChange={(e) => {
                        setMessageError(false);
                        setMessage(e.target.value);
                      }}
                      defaultValue=""
                      value={message}
                    />
                  </div>
                  <div className="col-12">
                    <button
                      onClick={(e) => handelSend(e)}
                      className="eg-btn btn--primary btn--md form--btn"
                      disabled={submitting}
                    >
                      ارسال
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="map-area wow fadeInUp"
                data-wow-duration="1.5s"
                data-wow-delay=".4s"
              >
                <iframe
                  title="google map"
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14489.094738692507!2d46.613192!3d24.7860803!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x72dd178a777312f6!2z2KjZhtin2YrYp9iqINiz2YbYqtix!5e0!3m2!1sar!2ssa!4v1659357804031!5m2!1sar!2ssa"
                  style={{ border: 0 }}
                  allowFullScreen
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactWrapper;
