import React from "react";

export default function ContactUs() {
  return (
    <div className="container ">
      <div className="row justify-content-center">
        <div className="col-md-6 text-center my-2">
          <div className="d-flex gap-1 justify-content-center">
            <h3>الرقم الموحد:</h3>
            <a href="tel:920023225" className="text-black text-primary">
              920023225
            </a>
          </div>
          <div className="d-flex gap-1 justify-content-center">
            <h3>البريد الإلكتروني:</h3>
            <a
              href="mailto:support@mobasher.sa"
              className="text-black text-primary"
            >
              : support@mobasher.sa
            </a>
          </div>
          <h3 className="mt-4">موقع الشركة على خرائط قوقل ماب :</h3>
          <iframe
            title="main-hq"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1810.7017220063549!2d46.68315333866783!3d24.815873044489596!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2ee3a4155aa9bf%3A0x8aec4aa3f3900f6!2z2YXYqNin2LTYsQ!5e0!3m2!1sen!2ssa!4v1713348213455!5m2!1sen!2ssa"
            style={{ border: 0, width: "100%" }}
            allowFullScreen={true}
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
          <p>الموقع: طريق الثمامة، حي الندى، مدينة الرياض 13317</p>
        </div>
      </div>
    </div>
  );
}
